/* ========================================================================
     Component: cards
 ========================================================================== */

.card {
    margin-bottom: 1.25rem; //20px
}

//
// Adds support to use more components inside cards
//
.card {
    .table {
        margin-bottom: 0;

        >thead>tr>th {
            border-top: 0;
        }
    }

    &.card-transparent {
        border: 0;
        background-color: transparent;
        box-shadow: 0 0 0 #000;

        .card-header,
        .card-body {
            background-color: transparent;
            padding-left: 0;
            padding-right: 0;
        }
    }

    // reset default inherited card border
    >.list-group {
        border-top: 0;
        border-bottom: 0;
    }
}


.card-flat {
    margin: 0 !important;
    border: 0;
}

.card-columns-2 {
    column-count: 1;

    @include media-breakpoint-up(md) {
        column-count: 2;
    }
}

.card-tool {
    display: inline-block;
    color: #fff;
    text-align: center;

    &:hover {
        cursor: pointer;
    }

    >em+em {
        margin-left: 12px;
    }
}

.card-header {

    .card-default & {
        >.card-tool {
            color: #c1c2c3;
        }
    }

    // right floated labels adjust position
    >.badge.float-right {
        margin-top: 3px;

        +.badge.float-right {
            margin-right: 10px;
        }
    }
}

.card-footer {
    .pagination {
        margin: 0;
    }

    .radial-bar {
        margin-bottom: 0;
    }

    p {
        margin-bottom: 0;
    }
}

.payout-card-underline {
    width: 30px;
}

.payout-card-middle-border {
    border-right: 2px solid black;
}

.pe-none-important {
    pointer-events: none !important;
}

.abs-button-tr {
    position: absolute;
    top: 1;
    right: 0px;
    z-index: 9;
}

.abs-button-profile-card {
    position: absolute;
    
    right: 15px;
    z-index: 9;
}

.padding-align-costs {
    padding-bottom: 22.1px;
}

.green-bg-text {
    margin: 0 0 0 0px;
    padding: 2px 5px;
    border-radius: 3px;
    background-color: #5cb85c;
}

.warning-bg-text {
    margin: 0 0 0 0px;
    padding: 2px 5px;
    border-radius: 3px;
    background-color: #f0ad4e;
}

.danger-bg-text {
    margin: 0 0 0 0px;
    padding: 2px 5px;
    border-radius: 3px;
    background-color: #d9534f;
}

.min-w-pod-price-card {
    min-width: 736px;
}

.min-w-student-sub-card {
    min-width: 554px;
}

.line-height-sub-card {
    line-height: 22px;
}

.dash-spacing {
    letter-spacing: -4px;
    margin-top: 10px;
}

.year-text-size {
    font-size: 12px;
}

.month-text-size {
    font-size: 15px;
}

.modal-height {
    height: 90%;
}

.zi-9 {
    position: relative;
    z-index: 9;
}

.f-size-30 {
    font-size: 30px;
}

.f-size-25 {
    font-size: 35px;
}

.f-size-20 {
    font-size: 20px;
}

.f-size-19 {
    font-size: 19px;
}

.f-size-15 {
    font-size: 15px;
}

@media (max-width: 500px) {
    .a-button-padding {
        padding: 5px 10px !important;
    }
    .d-none-max-500px {
        display: none !important;
    }
}

.users-subscription-icon-fixed-pos {
    position: absolute;
    bottom: 50px;
    right: 18px;
}

.storage-subscription-icon-fixed-pos {
    position: absolute;
    bottom: 50px;
    right: 15px;
}

.min-width-250px {
    min-width: 250px !important;
}