/* ========================================================================
     Component: top-navbar
 ========================================================================== */

//
// Horizontal top navbar
// based on the bootstrap navbar styles and markup
// ----------------------------------------------------

$nav-header-wd: $aside-wd;
$nav-header-wd-toggled: $aside-wd-collapsed;

$nav-header-wd-toggled-text: $aside-wd-collapsed-text;

$nav-top-bg: $info;
$nav-top-bg-start: $nav-top-bg;
$nav-top-bg-end: $info-light;
$nav-header-bg: transparent;

$nav-top-item-mobile: $nav-top-bg;
$nav-top-item-mobile-active: darken($nav-top-item-mobile, 20%);

$nav-top-item-desktop: #fff;
$nav-top-item-desktop-active: $nav-top-item-mobile-active;



// Navbar Mobile General styles
// ------------------------------
.topnavbar {


    .navbar-header-landing {
        position: relative;
        z-index: 11;
        padding-left: 0;

        @include media-breakpoint-up(md) {
            padding-left: 0;
        }

        // Reset default to allow handle via different logo size
        .navbar-brand {
            padding: 0;
        }

        // Different Logo size depends on the navbar state
        .brand-logo,
        .brand-logo-collapsed {
            >img {
                margin: 0 auto;
            }
        }

        .brand-logo {
            display: block;
            padding: 0px 15px;
        }

        .brand-logo-collapsed {
            display: none;
            padding: 0px 15px;
        }
    }


}


.sidebar-toggle-landing {
    z-index: 3001; // Add color only for the icon

    >em {
        color: white;
    }
}



// Navbar Landing Page Desktop Buttons
// ------------------------------
.button-header {
    border-radius: 4px;
    transition: 0.3s;

    &:hover {
        color: white;
        background-color: #3a3f51;
    }
}

.login {
    // color: #3a3f51;
    color: white;
    background-color: inherit;
    border: 1px solid white;
}

.signup {
    // color: #3a3f51;
    color: white;
    background-color: inherit;
    border: 1px solid white;
}

.demo {
    // color: #3a3f51;
    color: black;
    background-color: white;
    border: 1px solid  #eaeaea;
}

.login-offside {
    // color: #3a3f51;
    color: #3a3f51;
    background-color: inherit;
    border: 1px solid #3a3f51;
}

.signup-offside {
    // color: #3a3f51;
    color: #3a3f51;
    background-color: inherit;
    border: 1px solid #3a3f51;
}

.signup-fixed {
    color: white;
    background-color: inherit;
    border: 1px solid white;
    font-weight: bold;
}

.demo-fixed {
    color: black;
    background-color: white;
    border: 1px solid black;
    font-weight: bold;
}

.center-nav-buttons {
    color: white;
    width: 150px;
    height: $navbar-height;
    font-family: inherit;
    font-size: large !important;
    font-weight: bold;
    padding-top: 12px;
    border: 0;
    border-bottom: 2px solid transparent;
    border-radius: 0 !important;
    background-color: inherit;
    transition: 0.3s;

    &:hover {
        color: white !important;
        background-color: #3a3f51;
    }
}

.landing-page-container {
    padding-top: 15px;
    position: relative;
    top: 55px;
    width: 100%;
    height: 100%;
    text-align: center;
}

.hook-subtext-wrapper {
    display: inline-block;
}

.subheader-div {
    display: flex;
}

.subheader-text {
    flex-grow: 1;
    width: 0;
    word-wrap: 'break-word';
}

.no-bullets {
    list-style-type: none;
}

.landing-page-list>li {
    margin-bottom: 50px;
}

.pod-list>li {
    position: relative;
    left: 10px;
    padding-right: 20px;
    overflow-wrap: break-word;
}

section {
    display: block;
    box-sizing: inherit;
}

.landing-image {
    box-shadow: 4px 4px 18px rgba(0, 0, 0, .1);
    max-width: 90%;
    max-height: 350px;
    box-sizing: inherit;
}

.landing-image-list {
    width: 100px;
    margin-left: 15px;
    margin-bottom: 20px;
    max-height: 350px;
    box-sizing: inherit;
}

.landing-image-testimonials {
    margin-left: 15px;
    margin-bottom: 20px;
    max-height: 350px;
    box-sizing: inherit;
    border: 1.5px solid;
    border-radius: 5px;
}

.editorial {
    background-repeat: no-repeat;
    padding: 50px 0;
}

.editorial-last-row {
    background-repeat: no-repeat;
    padding: 50px 0px 0px 0px;
}

.padding-price-anchor {
    background-repeat: no-repeat;
}

#features,
#pricing {
    scroll-margin-top: 55px;
}

.editorial__container {
    float: none;
    display: inline-block;
    vertical-align: middle;
}

@media screen and (max-width: 751px) {
    .landing-image {
        margin-bottom: 30px;
    }

    .img-feature-text {
        margin-bottom: 30px;
    }

    .editorial.reverse-render .row {
        display: flex;
        -webkit-box-orient: vertical;
        /* autoprefixer: off */
        -webkit-box-direction: reverse;
        flex-direction: column-reverse;
        margin-left: -15px;
        margin-right: -15px;
        padding-left: 7px;
    }

    .editorial {
        padding: 40px 13px;
    }
}

.footer-landing-container {
    margin: 0 auto;
    max-width: 1280px;
    padding: 0 3.2rem;
    width: 100%;
    text-align: left;
}

.footer-nav {
    border-bottom: 1px solid black;
    padding-bottom: 3.2rem;
    padding-top: 3.4rem;
}

.footer-cell {
    display: grid;
    grid-template-rows: auto 1fr;
    margin: 0;
    padding-bottom: 3.2rem;
    page-break-inside: avoid;
}

.footer-disclaimer {
    color: #615e5c;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.4rem 0;
}

.footer-cell>ul {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    list-style-type: none;
    margin: 0.5rem 0 0 0.3rem;
    padding: 0;
}

.footer-cell a {
    color: inherit;
}

@media (min-width: 1024px) {
    .footer-nav {
        display: grid;
        gap: 3.2rem;
        grid-template-columns: repeat(6, 1fr);
    }
}

@media (min-width: 640px) and (max-width: 1024px) {
    .footer-nav {
        column-count: 3;
    }
}

.offsiderbar-ls-buttons {
    padding-top: 1.5rem;
    padding-bottom: 10.5rem;
    padding-inline-start: 50px;
    margin-block-start: 1em;
    margin-block-end: 1em;
}

.landing-sidebar-wrapper {
    position: relative;
    height: 100%;
}

.offsidebar-vh {
    height: 100vh !important;
}

.second-section-wrapper {
    display: flex;
    height: auto;
    width: 100%;
    padding-right: 3.5rem;
    padding-left: 3.5rem;

    @include media-breakpoint-down(md) {
        flex-direction: column;
    }

}

.second-section-wrapper div:first-child {
    padding-right: 3.5rem;
}

.second-section-wrapper div:nth-child(2) {
    padding-top: 1.5rem;
}

.landing-h1-text {
    color: rgb(21, 47, 55);
    font-weight: 500;
    font-size: 2.25rem;
    line-height: 2.25rem;

    @media (min-width: 768px) {
        font-size: 3rem;
        line-height: 1;
    }

}

.landing-h1-text-whypod {
    color: rgb(21, 47, 55);
    font-weight: 500;
    font-size: 1.875rem;
    line-height: 2.25rem;

    @media (min-width: 768px) {
        font-size: 2.4rem;
        line-height: 1;
    }

}

.landing-h2-text {
    color: rgb(21, 47, 55);
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2rem;

    @media (min-width: 768px) {
        font-size: 2.25rem;
        line-height: 2.5rem;
    }

}

.landing-h2-text-whypod {
    color: rgb(21, 47, 55);
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2rem;

    @media (min-width: 768px) {
        font-size: 1.5rem;
        line-height: 2.5rem;
    }

}

.landing-h3-text {
    color: rgb(21, 47, 55);
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    border-radius: 0.25rem;
}

.landing-paragraph-text {
    color: rgb(21, 47, 55);
    font-weight: 300;
    font-size: .875rem;
    line-height: 1.25rem;

    @media (min-width: 768px) {
        font-size: 1rem;
        line-height: 1.5rem;
    }
}

.active-landing-section {
    border-bottom: 2px solid white;
}


// landing page price section styling

.pricing-container {
    display: block;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    text-align: left;
}

.pricing-row {
    display: flex;
    justify-content: center;
    zoom: 1;
    margin-bottom: 40px;
}

.pricing-columns-12 {
    padding-left: 10px;
    padding-right: 10px;
    flex-basis: calc(12 / 12 * 100%);
}

.price-cards {
    display: flex;
    justify-content: center;
    gap: 25px;
    margin-bottom: 25px;

    @media (max-width: 3250px) {
        max-width: 1400px;
    }

    @media (max-width: 1887px) {
        flex-wrap: wrap;
        gap: 65px;
    }

    @media (max-width: 768px) {
        padding-left: 50px;
        padding-right: 50px;
        flex-direction: column;
        align-items: center;
    }

    @media (max-width: 400px) {
        flex-direction: column;
        padding-left: 15px;
        padding-right: 15px;
    }
}

.price-card {
    box-shadow: 4px 4px 18px rgba(0, 0, 0, .1);
    border-radius: 20px;
    max-width: 360px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    min-width: 330px;

    @media (max-width: 1300px) {
        width: 90%;
        margin-bottom: 32px;
    }

    @media (max-width: 768px) {
        width: 95%;
        margin-bottom: 32px;
    }

    @media (max-width: 400px) {
        width: 100%;
        margin-top: 20px;
    }
}

.break {
    flex-basis: 100%;
    height: 0;
    width:100%
}

.price-header {
    display: flex;
    justify-content: flex-start;
    position: relative;
    background: #3A4A54;
    margin-top: -33px;
    margin-bottom: 30px;
    margin-left: -33px;
    margin-right: -33px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 8px;
    overflow: hidden;
    column-gap: 15px;
}

.price-card-title {
    text-align: left;
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    text-transform: uppercase;
    padding-top: 8px;
    padding-bottom: 8px;
    color: #fff;
    margin-left: 25px;

    &-free {
        text-align: left;
        font-size: 30px;
        font-weight: 700;
        line-height: 40px;
        text-transform: uppercase;
        padding-top: 8px;
        padding-bottom: 0px;
        margin-bottom: 0px;
        color: #fff;
        margin-left: 25px;
    }

    &-subtext {
        text-align: left;
        font-size: 12px;
        font-weight: 700;
        line-height: 9.7px;
        text-transform: none;
        padding-top: 0px;
        padding-bottom: 8px;
        margin-top: 0px;
        margin-bottom: 0px;
        color: #fff;
        margin-left: 35px;
    }
}

.top-blurb {
    color: #5C5C5C;
    font-weight: 600;
    font-size: 19px;
    line-height: 24px;
    margin-bottom: 25px;
}

.amount-container {
    position: relative;
    margin-bottom: 25px;
}

.price-amount {
    color: #333;
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
}

.price-amount-2 {
    color: #333;
    font-weight: 700;
    font-size: 16px;
    line-height: 40px;
}

.price-month-2 {
    font-size: 12px;
}

.price-month {
    font-size: 25px;
}

.ul-padding {
    padding-inline-start: 20px;
}

.price-features {
    color: #5C5C5C;
    font-weight: 400;
    font-size: 16px;
    flex: 1;
}

.bg-secondary-gray {
    background-color: #f5f7fa;
}

.collapseable-plus {
    font-size: 20px;
}

.faq-ul-text {
    font-size: 1.25rem;
    line-height: 1.5em;
    font-weight: 400;
}

.min-width-920 {
    display: flex;
    min-width: 920px;
}

@media (max-width: 992px) {
    .d-none-max-992 {
        display: none;
    }
}

@media (min-width: 993px) {
    .d-none-min-992 {
        display: none;
    }
}

@media (max-width: 1920px) {
    .d-none-max-1992 {
        display: none;
    }
}

@media (min-width: 1921px) {
    .d-none-min-1992 {
        display: none;
    }
}

.carousel-indicators-below {
    position: relative;
    margin-top: 25px;
}

.carousel-indicators li {
    border: 2px solid black;
    border-radius: 50%;
    width: 15px;
    height: 15px;
}


.flex-content {
    display: flex;
    overflow: auto;
}

.student-portal-image-display {
    display: none;
}

@media (min-width: 992px) {
    .student-portal-image-display {
        display: block;
    }
}

.student-portal-text-display {
    display: block;
}

@media (min-width: 992px) {
    .student-portal-text-display {
        display: none;
    }
}

.logo {
    width: 100%;
    max-width: 143px;
    max-height: 45px;
}

.logo-collapsed {
    width: 100%;
    max-width: 32px;
    max-height: 45px;
}

.button-prices {
    border-radius: 4px;
    transition: 0.3s;

    &:hover {
        background-color: #3A4A54;
    }
}

.prices-signup-button {
    width: 100% !important;
    font-size: 20px !important;
    color: white;
}

.color-inherit { 
    color: inherit;
}

.landing-image-header {
    box-shadow: 4px 4px 18px rgba(0, 0, 0, .1);
    max-width: 800px;
    width: 130%;
    box-sizing: inherit;
    margin-left: -112px;
}

.h1-header-text {
    font-weight: 800;
    line-height: 1.3;
    text-align: left;
    margin-bottom: -10px;
    letter-spacing: -.4px;

    @media (max-width: 1200px) {
        text-align: center;
    }
    
}

.h1-subheader-text {
    font-size: 20px;
    margin-right: 33%;
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
    width: auto;
    max-width: 320px;
    text-align: left;
    margin: 30px 0;

    @media (max-width: 1200px) {
        max-width: none;
        text-align: center;
    }
    
}

.headert {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    gap: 150px;

    @media (max-width: 1200px) {
        flex-wrap: wrap;
        gap: 50px;
    }
}

.header-text {
    max-width: 400px;
    @media (max-width: 1200px) {
        max-width: 75%;
    }
}

.header-buttons-container {
    gap: 15px;
    @media (max-width: 460px) {
        flex-direction: column;
        flex-wrap: wrap;
    }
}

.header-signup-button {
    font-size: 20px !important;
    color: white;

    @media (max-width: 1200px) {
        width: 120px !important;
    }
}

.header-demo-button {
    font-size: 20px !important;
   
    @media (max-width: 1200px) {
        width: 160px !important;
    }

    &:hover {
        color: white;
        background-color: #3a3f51;
    }
}

.header-image {
    width: 100%;
    max-width: 800px; /* Adjust the max-width as needed */
    height: auto;
}

.tweet {
    margin: 20px!important;
    padding: 20px 20px 0;
    margin-bottom: 0!important;
    width: 100%;
    box-sizing: border-box;
}

.tweet-content-wrapper {
    position: relative;
    padding: 22px;
    background-color: #fff;
    box-shadow: 0 7px 24px 0 rgba(0,0,0,.06);
    box-sizing: border-box;
    border-radius: 6px;
    width: 100%;
}

.tweet-header {
    margin-bottom: 25px!important;
    height: 36px;
    color: #2c3d4e;
}

.tweet-author {
    width: 80%;
}

.tweet-author-picture {
    float: left;
    width: 36px;
    height: 36px;
    border-radius: 92px;
    margin-right: 10px;
}

.tweet-author-name {
    font-size: 16px;
    height: 20px;
    color: #2c3d4e;
}

.tweet-author-link {
    margin: 0;
    font-size: 14px;
    height: 18px;
    color: #757575!important;
}

.wrap-below-1430px {
    @media (max-width: 1430px) {
        flex-wrap: wrap;
    }
}