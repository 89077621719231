/* ========================================================================
     Component: scrollbar
 ========================================================================== */

::-webkit-scrollbar {
    height: .55em;
    width: .55em;
}

@media (max-width: 768px) {
    ::-webkit-scrollbar {
        height: .55em;
        width: .55em;
    }
}

.thin-scrollbar::-webkit-scrollbar {
        height: .25em !important;
        width: .25em !important;
}

::-webkit-scrollbar-track {
    background: inherit;
}

::-webkit-scrollbar-track:horizontal{
    background: inherit;
}

::-webkit-scrollbar-thumb {
    background: #878684;
    border-radius: 100vw;
}

::-webkit-scrollbar-thumb:horizontal {
    background: #878684;
    border-radius: 100vw;
}

::-webkit-scrollbar-thumb:hover {
    background: #6b6a68;
}

::-webkit-scrollbar-thumb:hover:horizontal {
    background: #6b6a68;
}

::-webkit-scrollbar-thumb:active {
    background: #525150;
}

::-webkit-scrollbar-thumb:active:horizontal {
    background: #525150;
}

@supports (scrollbar-color: #878684 inherit) {
    * {
        scrollbar-color: #878684 inherit;
        scrollbar-width: thin;
    }
}