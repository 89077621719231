.centered-container {
    margin-top: 110px;
    margin-right: auto;
    margin-left: auto;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    // min-width: 940px;
    max-width: 1150px;
    padding-left: 3%;
    padding-right: 3%;
    display: flex;
}

@media (min-width: 1005px) {
    html[data-device-type=dedicated] .containerCentered {
        padding-left: 3%;
        padding-right: 3%;
    }
}

.privacy-section-container {
    position: relative;
    z-index: 2;
    -webkit-transform: translate3d(0,0,0);
    transform: translate(0,0);
    overflow: auto;
    width: 100%;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.privacy-main-header {
    font-size: 2.55rem;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: .02em;
    
}

.privacy-section-heading {
    font-size: 1.95rem;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: .01em;
}

.privacy-detail-text {
    font-size: 1.25rem;
    line-height: 1.5em;
    font-weight: 400;
}

#pl {
    &-1, &-2, &-3, &-4, &-5, &-6, &-7 {
        scroll-margin-top: 55px;
    }
}

#tos {
    &-1, &-2, &-3, &-4, &-5, &-6, &-7, &-8, &-9, &-10, &-11, &-12 {
        scroll-margin-top: 55px;
    }
}