.creditcard {
    width: 100%;
    height: 100%;

    &-inner {
        position: relative;
        width: 100%;
        height: 100%;
    }

    &-front,
    &-back {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
    }

    &-contactless {
        position: absolute;
        left: 205px;
        transform: scale(0.5);
        filter: invert(34%) sepia(86%) saturate(5202%) hue-rotate(195deg) brightness(97%) contrast(98%);
    }

    &-chip {
        position: absolute;
        top: 20px;
        left: 15px;
        width: 45px;
        height: 34px;
        border-radius: 5px;
        background-color: #ffda7b;
        overflow: hidden;

        &::before {
            content: "";
            position: absolute;
            left: 49%;
            top: -7%;
            transform: translateX(-50%);
            background: #ffda7b;
            border: 1px solid #a27c1f;
            width: 25%;
            height: 110%;
            border-radius: 100%;
            z-index: 1;
        }

        &::after {
            content: "";
            position: absolute;
            top: 30%;
            left: -10%;
            background: transparent;
            border: 1px solid #a27c1f;
            width: 120%;
            height: 33%;
        }
    }

    &-holder {
        position: absolute;
        left: 15px;
        bottom: 1px;
        color: black;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.2em;
    }

    &-number {
        position: absolute;
        left: 15px;
        bottom: 20px;
        color: black;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.2em;
        filter: drop-shadow(1px 1px 1px rgba(black, 0.3));
    }

    &-valid {
        position: absolute;
        right: 25px;
        bottom: 15px;
        color: black;
        font-size: 14px;
        letter-spacing: 0.2em;
        filter: drop-shadow(1px 1px 1px rgba(black, 0.3));

        &::before {
            content: "GOOD THRU";
            position: absolute;
            top: 1px;
            left: -35px;
            width: 50px;
            font-size: 7px;
        }
    }
}

.cclogo{
    position: absolute;
    bottom: 20px;
    right: 15px;
}