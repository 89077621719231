/* ========================================================================
     Component: table-extras
 ========================================================================== */


// Add support to components inside tables

.table {
    >thead>tr>th {
        padding: 14px 8px;
        color: #888;
    }

    >tbody>tr>td {
        vertical-align: middle;

        >.media {
            img {
                width: 36px;
                height: 36px;
                margin: 0 auto;
            }
        }
    }

    .checkbox {
        margin: 0 auto;
        width: 20px;
    }

    .progress {
        margin-bottom: 0
    }

    .radial-bar {
        margin-bottom: 0;
        margin: 0 auto;
    }

}

.jqstooltip {
    box-sizing: content-box;
}

// Extended table demos

#table-ext-1 {
    th {
        &:nth-child(1) {
            width: 3%;
        }

        &:nth-child(2) {
            width: 5%;
        }

        &:nth-child(7) {
            width: 5%;
        }

        &:nth-child(9) {
            width: 5%;
        }

    }

}

#table-ext-2 {
    th {
        &:nth-child(1) {
            width: 5%;
        }

        &:nth-child(3) {
            width: 10%;
        }

    }
}

.not-found-td {
    border: 0px !important;
}

.transaction-amount-success {
    position: relative;
    float: none;
    clear: both;
    margin: 0 0 0 30px;
    padding: 2px 5px;
    border-radius: 3px;
    background-color: #d6f6c0;
    color: #336d26;
}

.subscriptions-table-row .transaction-amount-success {
    position: relative;
    float: none;
    clear: both;
    margin: 0 0 0 0;
    padding: 2px 5px;
    border-radius: 3px;
    background-color: #d6f6c0;
    color: #336d26;
}

.standings-table-row .transaction-amount-success {
    position: relative;
    float: none;
    clear: both;
    margin: 0 0 0 0;
    padding: 2px 5px;
    border-radius: 3px;
    background-color: #d6f6c0;
    color: #336d26;
}

.transaction-amount-info {
    position: relative;
    float: none;
    clear: both;
    margin: 0 0 0 30px;
    padding: 2px 5px;
    border-radius: 3px;
    background-color: #e8ecef;
    color: #565a63;
}

.transaction-amount-canceled {
    position: relative;
    float: none;
    clear: both;
    margin: 0 0 0 30px;
    padding: 2px 5px;
    border-radius: 3px;
    background-color: #e08787;
    color: #6e0808;
}

.subscriptions-table-row .transaction-amount-canceled {
    position: relative;
    float: none;
    clear: both;
    margin: 0 0 0 0px;
    padding: 2px 5px;
    border-radius: 3px;
    background-color: #e08787;
    color: #6e0808;
}

.standings-table-row .transaction-amount-canceled {
    position: relative;
    float: none;
    clear: both;
    margin: 0 0 0 0px;
    padding: 2px 5px;
    border-radius: 3px;
    background-color: #e08787;
    color: #6e0808;
}

.first-td.width {
    width: 70%
}

.table-within-nav-card td {
    white-space: nowrap;
}

.table-within-card {
    table-layout: fixed;
    width: 100%;

    td:nth-child(2) {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    td:first-child {
        width: 25%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

}

.charges-table-row td:first-child {
    width: 20%;
}

.reports-table-row td:first-child,
.reports-table-row th:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 2;
    background: #FAFBFC;
}

.table-row-divider {
    border: solid;
    border-width: 2px 0;
}

.status-green {
    position: relative;
    white-space: nowrap;
    float: none;
    clear: both;
    margin: 0 0 0 0;
    padding: 2px 5px;
    border-radius: 3px;
    background-color: #d6f6c0;
    color: #336d26
}

.status-yellow {
    position: relative;
    white-space: nowrap;
    float: none;
    clear: both;
    margin: 0 0 0 0;
    padding: 2px 5px;
    border-radius: 3px;
    background-color: #facb89;
    color: #6b4612;
}

.status-red {
    position: relative;
    white-space: nowrap;
    float: none;
    clear: both;
    margin: 0 0 0 0;
    padding: 2px 5px;
    border-radius: 3px;
    background-color: #e08787;
    color: #6e0808;
}

.status-light-blue {
    position: relative;
    white-space: nowrap;
    float: none;
    clear: both;
    margin: 0 0 0 0;
    padding: 2px 5px;
    border-radius: 3px;
    background-color: #0dcaf0;
    color: #055160;
}

.line-height-body {
    line-height: 1.52857;
}